import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class CorporateSecretarial extends React.Component {
	render() {
		const siteTitle = 'Corporarte Secretarial Services'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Corporate Secretarial Services</h3>
								<p>
									Due to ever changing corporate legislation it has become
									imperative for businesses to obtain specialized assistance.
									WSC Group prides itself on its Corporate Secretarial Services
									expertise, providing a complete range of corporate secretarial
									and support services for businesses both large and small.{' '}
								</p>
								<p>
									We specialize in the registration of companies, provisions of
									trust deeds and searching of corporate structures, business
									names and title searches. We also maintain company and trust
									registers and facilitate the issue, transfer and cancellation
									of both shares and units in unit trusts.
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default CorporateSecretarial

export const CorporateSecretarialPageQuery = graphql`
	query CorporateSecretarialPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
